<template>
  <div>
    <div class="rows" :dir="$t('clan')=='ar'?'rtl':'ltr'">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$t('Customers')}}</h3>
            </div>
          </div>
          <div class="card-body">
            <div>
               
              <CustomersTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomersTable from "@/view/pages/customers/CustomersTable.vue";
export default {
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Customers", route: "/admin/customers" },
    ]);


    //Loads in layout to reduce the delay
  },
  components: {
    CustomersTable,
  },
  methods: {
    
    viewCustomer: function (id) {
      this.$router.push({ name: "ViewCustomer", params: { id: id } });
    },
  },
  data: function () {
    return {
      
    };
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}

</style>